// База данни съдържаща песни и съответните им неравноделни размери
export const dances = {
	"2per4": [
		["A, Bre Nikola","право"],
		["A, BRE NIKOLA","право"],
		["A BRE, NIKOLA","право"],
		["ABRE NIKOLA","право"],
		["Abre Nikola","право"],
		["А бре, Никола","право"],
		["A Bre Nikola","право"],
		["Kitka 1(Pesen Za Bratyata)",""],
		["Kitka 1 (Pesen Za Bratyata)",""],
		["Kitka 1(Pesen za bratyata)",""],
		["Kitka 1 (Pesen za bratyata)",""],
		["Китка 1(Песен за братята)",""],
		["Китка 1 (Песен за братята)",""],
		["Китка 1(Песен За Братята)",""],
		["Китка 1 (Песен За Братята)",""],
		["Tragna Moma Za Voda",""],
		["Tragna moma za voda",""],
		["Trugna moma za voda",""],
		["Trugna Moma Za Voda",""],
		["Tragna Moma Za Voda",""],
		["Тръгна мома за вода",""],
		["Тръгна Мома За Вода",""],
		["Dunavsko Horo","дунавско"],
		["Dunavskoto Horo","дунавско"],
		["Dunavsko horo","дунавско"],
		["Dunavskoto horo","дунавско"],
		["Dunavskoto","дунавско"],
		["dunavskoto","дунавско"],
		["DUNAVSKO HORO","дунавско"],
		["ДУНАВСКО ХОРО","дунавско"],
		["ДУНАВКО ХОРО","дунавско"],
		["DUNAVSKOTO HORO","дунавско"],
		["ДУНАВСКОТО ХОРО","дунавско"],
		["DUNAVSKOTO","дунавско"],
		["ДУНАВСКОТО","дунавско"],
		["Дунавско Хоро","дунавско"],
		["Дунавско хоро","дунавско"],
		["Граовско хоро","граовско"],
		["Граовско Хоро","граовско"],
		["Pravo Ihtimansko Horo","право"],
		["Pravo ihtimansko horo","право"],
		["Право ихтиманско хоро","право"],
		["Право Ихтиманско хоро","право"],
		["Ситно граовско хоро","граовско"],
		["Ситно Граовско Хоро","граовско"],
		["Sitno Graovsko Horo","граовско"],
		["Sitno graovsko horo","граовско"],
		["Приятелю Мой (Македонско Хоро – Ширто)","ширто"],
		["Приятелю мой (Македонско хоро – ширто)","ширто"],
		["Shopsko Horo","шопско"],
		["Shopsko horo","шопско"],
		["Shopshko Horo","шопско"],
		["Шопско хоро","шопско"],
		["Шопско право хоро","право"],
		["Shopsko pravo horo","шопско"],
		["Шопско Хоро","шопско"],
		["Щеркольовата",""],
		["Щеркольовата-Калино, Радо",""],
		["Graovsko Horo, Part 1","граовско"],
		["Graovsko Horo","граовско"],
		["Graovsko horo, part 1","граовско"],
		["Graovsko horo","граовско"],
		["Граовско хоро, част 1","граовско"],
		["Граовско хоро","граовско"],
		["Граовско Хоро, част 1","граовско"],
		["Граовско Хоро","граовско"],
		["Чичови Мъки",""],
		["Чичови мъки",""],
		["Chichovi muki",""],
		["Chichovi Muki",""],
		["Chichovi maki",""],
		["Chichovi Maki",""],
		["Боряно, Борянке",""],
		["Боряно, борянке",""],
		["Буряно, Бурянке",""],
		["Буряно, бурянке",""],
		["Boryano, boryanke",""],
		["Boryano, Boryanke",""],
		["Buryano, buryanke",""],
		["Buryano, Buryanke",""],
		["Borqno, borqnke",""],
		["Borqno, Borqnke",""],
		["Burqno, burqnke",""],
		["Burqno, Burqnke",""],
		["Nestinarsko Horo","нестинарско"],
		["Nestinarsko horo","нестинарско"],
		["Нестинарско хоро","нестинарско"],
		["Нестинарско Хоро","нестинарско"],
		["graovsko","граовско"],
		["Graovsko","граовско"],
		["GRAOVSKO","граовско"],
		["graovskoto","граовско"],
		["Graovskoto","граовско"],
		["GRAOVSKOTO","граовско"],
		["graovsko horo","граовско"],
		["Graovsko horo","граовско"],
		["Graovsko Horo","граовско"],
		["GRAOVSKO HORO","граовско"],
		["graovskoto horo","граовско"],
		["Graovskoto horo","граовско"],
		["Graovskoto Horo","граовско"],
		["GRAOVSKOTO HORO","граовско"],
		["Граовско","граовско"],
		["граовско","граовско"],
		["ГРАОВСКО","граовско"],
		["Граовското","граовско"],
		["граовското","граовско"],
		["ГРАОВСКОТО","граовско"],
		["Граовско Хоро","граовско"],
		["Граовско хоро","граовско"],
		["граовско хоро","граовско"],
		["ГРАОВСКО ХОРО","граовско"],
		["Граовското Хоро","граовско"],
		["Граовското хоро","граовско"],
		["граовското хоро","граовско"],
		["ГРАОВСКОТО ХОРО","граовско"],
		["Katerino mome","ширто"],
		["Katerino Mome","ширто"],
		["Катерино моме","ширто"],
		["Катерино Моме","ширто"],
		["КАТЕРИНО МОМЕ","ширто"],
		["KATERINO MOME","ширто"],
		["Qsna Gaida E Pisnala", ""],
		["Qsna gaida e pisnala", ""],
		["Yasna Gaida E Pisnala", ""],
		["Yasna gaida e pisnala", ""],
		["Ясна гайда е писнала", ""],
		["Ясна Гайда Е Писнала", ""],
		["Иванчо Нива Ореше -Гергьовденско Хоро: Китка (Право Хоро)","право"],
		["Arap","арап"],
		["ARAP","арап"],
		["arap","арап"],
		["Arap horo","арап"],
		["ARAP HORO","арап"],
		["arap horo","арап"],
		["Arap Horo","арап"],
		["Арап","арап"],
		["AРАП","арап"],
		["арап","арап"],
		["Арап хоро","арап"],
		["Арап Хоро","арап"],
		["AРАП ХОРО","арап"],
		["арап хоро","арап"],
		["Арап (Arap)","арап"],
		["Арап Хоро - Arap Horo","арап"],
		["Neveno mome","арап"],
		["Neveno Mome","арап"],
		["NEVENO MOME","арап"],
		["Невено моме","арап"],
		["Невено Моме","арап"],
		["НЕВЕНО МОМЕ","арап"],
		["Невено моме (Арап)","арап"],
		["Neveno mome (Arap)","арап"],
		["Невено моме (Арап) / Neveno mome (Arap horo)","арап"],
		["хоро Арап","арап"],
		["Хоро Арап","арап"],
		["ХОРО AРАП","арап"],
		["хоро арап","арап"],
		["horo Arap","арап"],
		["HORO ARAP","арап"],
		["horo arap","арап"],
		["Horo Arap","арап"],
		["влашко","влашко"],
		["Влашко","влашко"],
		["ВЛАШКО","влашко"],
		["влашкото","влашко"],
		["Влашкото","влашко"],
		["ВЛАШКОТО","влашко"],
		["vlashko","влашко"],
		["Vlashko","влашко"],
		["VLASHKO","влашко"],
		["vlashkoto","влашко"],
		["Vlashkoto","влашко"],
		["VLASHKOTO","влашко"],
		["влашко хоро","влашко"],
		["Влашко Хоро","влашко"],
		["Влашко хоро","влашко"],
		["ВЛАШКО ХОРО","влашко"],
		["влашкото хоро","влашко"],
		["Влашкото Хоро","влашко"],
		["Влашкото хоро","влашко"],
		["ВЛАШКОТО ХОРО","влашко"],
		["vlashko horo","влашко"],
		["Vlashko Horo","влашко"],
		["Vlashko horo","влашко"],
		["VLASHKO HORO","влашко"],
		["vlashkoto horo","влашко"],
		["Vlashkoto Horo","влашко"],
		["Vlashkoto horo","влашко"],
		["VLASHKOTO HORO","влашко"],
		["ситно влашко","влашко"],
		["Ситно Влашко","влашко"],
		["Ситно влашко","влашко"],
		["СИТНО ВЛАШКО","влашко"],
		["Sitno vlashko","влашко"],
		["Sitno Vlashko","влашко"],
		["sitno vlashko","влашко"],
		["SITNO VLASHKO","влашко"],
		["ситно влашко хоро","влашко"],
		["Ситно Влашко Хоро","влашко"],
		["Ситно Влашко хоро","влашко"],
		["Ситно влашко хоро","влашко"],
		["СИТНО ВЛАШКО ХОРО","влашко"],
		["sitno vlashko horo","влашко"],
		["Sitno Vlashko Horo","влашко"],
		["Sitno Vlashko horo","влашко"],
		["Sitno vlashko horo","влашко"],
		["SITNO VLASHKO HORO","влашко"],
		["Praznichno vlashko","влашко"],	
		["gumzovqna","гъмзовяна"],
		["gumzovyana","гъмзовяна"],
		["gamzovqna","гъмзовяна"],
		["gamzovyana","гъмзовяна"],
		["Gumzovqna","гъмзовяна"],
		["Gumzovyana","граовско"],
		["Gamzovyana","граовско"],
		["Gamzovqna","граовско"],
		["GAMZOVQNA","гъмзовяна"],
		["GAMZOVYANA","граовско"],
		["GUMZOVQNA","граовско"],
		["GUMZOVYANA","граовско"],
		["Gumzovqna horo","гъмзовяна"],
		["Gumzovyana horo","гъмзовяна"],
		["Gumzovqna Horo","граовско"],
		["Gumzovyana Horo","граовско"],
		["Gamzovqna horo","гъмзовяна"],
		["Gamzovyana horo","гъмзовяна"],
		["Gamzovqna Horo","граовско"],
		["Gamzovyana Horo","граовско"],
		["GUMZOVQNA HORO","гъмзовяна"],
		["GUMZOVYANA HORO","гъмзовяна"],
		["GAMZOVQNA HORO","гъмзовяна"],
		["GAMZOVYANA HORO","граовско"],
		["Гъмзовяна","гъмзовяна"],
		["гъмзовяна","гъмзовяна"],
		["ГЪМЗОВЯНА","гъмзовяна"],
		["Гъмзовяна Хоро","гъмзовяна"],
		["Гъмзовяна хоро","гъмзовяна"],
		["гъмзовяна хоро","гъмзовяна"],
		["ГЪМЗОВЯНА ХОРО","гъмзовяна"],
		["enino","енино"],
		["Enino","енино"],
		["ENINO","енино"],
		["eninoto","енино"],
		["Eninoto","енино"],
		["ENINOTO","енино"],
		["enino horo","енино"],
		["Enino horo","енино"],
		["Enino Horo","енино"],
		["ENINO HORO","енино"],
		["eninoto horo","енино"],
		["Eninoto horo","енино"],
		["Eninoto Horo","енино"],
		["ENINOTO HORO","енино"],
		["Енино","енино"],
		["енино","енино"],
		["ЕНИНО","енино"],
		["Ениното","енино"],
		["ениното","енино"],
		["ЕНИНОТО","енино"],
		["Енино Хоро","енино"],
		["Енино хоро","енино"],
		["енино хоро","енино"],
		["ЕНИНО ХОРО","енино"],
		["Ениното Хоро","енино"],
		["Ениното хоро","енино"],
		["Ениното хоро","енино"],
		["ЕНИНОТО ХОРО","енино"],
		["ЕНИНО ХОРО","енино"],
		["RIPNI KALINKE","енино"],
		["Ripni kalinke","енино"],
		["Ripni Kalinke","енино"],
		["РИПНИ КАЛИНКЕ","енино"],
		["Рипни калинке","енино"],
		["Рипни Калинке","енино"],
		["RIPNI, KALINKE","енино"],
		["Ripni, kalinke","енино"],
		["Ripni, Kalinke","енино"],
		["РИПНИ, КАЛИНКЕ","енино"],
		["Рипни, калинке","енино"],
		["Рипни, Калинке","енино"],
		["izruchanka","изручанка, влашко"],
		["Izruchanka","изручанка, влашко"],
		["IZRUCHANKA","изручанка, влашко"],
		["izruchanka horo","изручанка, влашко"],
		["Izruchanka horo","изручанка, влашко"],
		["Izruchanka Horo","изручанка, влашко"],
		["IZRUCHANKA HORO","изручанка, влашко"],
		["Изручанка","изручанка, влашко"],
		["изручанка","изручанка, влашко"],
		["ИЗРУЧАНКА","изручанка, влашко"],
		["Изручанка Хоро","изручанка, влашко"],
		["Изручанка хоро","изручанка, влашко"],
		["изручанка хоро","изручанка, влашко"],
		["ИЗРУЧАНКА ХОРО","изручанка, влашко"],
		["izrochanka","изручанка, влашко"],
		["Izrochanka","изручанка, влашко"],
		["IZROCHANKA","изручанка, влашко"],
		["izrochanka horo","изручанка, влашко"],
		["Izrochanka horo","изручанка, влашко"],
		["Izrochanka Horo","изручанка, влашко"],
		["IZROCHANKA HORO","изручанка, влашко"],
		["Изрочанка","изручанка, влашко"],
		["изрочанка","изручанка, влашко"],
		["ИЗРОЧАНКА","изручанка, влашко"],
		["Изрочанка Хоро","изручанка, влашко"],
		["Изрочанка хоро","изручанка, влашко"],
		["изрочанка хоро","изручанка, влашко"],
		["ИЗРОЧАНКА ХОРО","изручанка, влашко"],
		["kasapsko","касапско, арап"],
		["Kasapsko","касапско, арап"],
		["KASAPSKO","касапско, арап"],
		["kasapskoto","касапско, арап"],
		["Kasapskoto","касапско, арап"],
		["KASAPSKOTO","касапско, арап"],
		["kasapsko horo","касапско, арап"],
		["Kasapsko horo","касапско, арап"],
		["Kasapsko Horo","касапско, арап"],
		["KASAPSKO HORO","касапско, арап"],
		["kasapskoto horo","касапско, арап"],
		["Kasapskoto horo","касапско, арап"],
		["Kasapskoto Horo","касапско, арап"],
		["KASAPSKOTO HORO","касапско, арап"],
		["Касапско","касапско, арап"],
		["касапско","касапско, арап"],
		["КАСАПСКО","касапско, арап"],
		["Касапското","касапско, арап"],
		["касапското","касапско, арап"],
		["КАСАПСКОТО","касапско, арап"],
		["Касапско Хоро","касапско, арап"],
		["Касапско хоро","касапско, арап"],
		["касапско хоро","касапско, арап"],
		["КАСАПСКО ХОРО","касапско, арап"],
		["Касапското Хоро","касапско, арап"],
		["Касапското хоро","касапско, арап"],
		["касапското хоро","касапско, арап"],
		["КАСАПСКОТО ХОРО","касапско, арап"],
		["Бавна Мелодия И Касапско Хоро","касапско"],
		["Бавна мелодия и касапско хоро","касапско"],
		["Bavna Melodiq I Kasapsko Horo","касапско"],
		["Bavna melodiq i kasapsko horo","касапско"],
		["kremikovsko","кремиковско"],
		["Kremikovsko","кремиковско"],
		["KREMIKOVSKO","кремиковско"],
		["kremikovskoto","кремиковско"],
		["Kremikovskoto","кремиковско"],
		["KREMIKOVSKOTO","кремиковско"],
		["kremikovsko horo","кремиковско"],
		["Kremikovsko horo","кремиковско"],
		["Kremikovsko Horo","кремиковско"],
		["KREMIKOVSKO HORO","кремиковско"],
		["kremikovsko horo","кремиковско"],
		["Kremikovskoto horo","кремиковско"],
		["Kremikovskoto Horo","кремиковско"],
		["KREMIKOVSKOTO HORO","кремиковско"],
		["Кремиковско","кремиковско"],
		["кремиковско","кремиковско"],
		["КРЕМИКОВСКО","кремиковско"],
		["Кремиковското","кремиковско"],
		["кремиковското","кремиковско"],
		["КРЕМИКОВСКОТО","кремиковско"],
		["Кремиковско Хоро","кремиковско"],
		["Кремиковско хоро","кремиковско"],
		["кремиковско хоро","кремиковско"],
		["КРЕМИКОВСКО ХОРО","кремиковско"],
		["Кремиковското Хоро","кремиковско"],
		["Кремиковското хоро","кремиковско"],
		["кремиковското хоро","кремиковско"],
		["КРЕМИКОВСКОТО ХОРО","кремиковско"],
		["sitno kremikovsko","кремиковско"],
		["Sitno Kremikovsko","кремиковско"],
		["Sitno kremikovsko","кремиковско"],
		["SITNO KREMIKOVSKO","кремиковско"],
		["sitnoto kremikovsko","кремиковско"],
		["Sitnoto Kremikovsko","кремиковско"],
		["SITNOTO KREMIKOVSKO","кремиковско"],
		["sitno kremikovsko horo","кремиковско"],
		["Sitno Kremikovsko horo","кремиковско"],
		["Sitno kremikovsko horo","кремиковско"],
		["Sitno Kremikovsko Horo","кремиковско"],
		["SITNO KREMIKOVSKO HORO","кремиковско"],
		["Sitnoto Kremikovsko horo","кремиковско"],
		["sitnoto kremikovsko horo","кремиковско"],
		["Sitnoto Kremikovsko Horo","кремиковско"],
		["SITNOTO KREMIKOVSKO HORO","кремиковско"],
		["Ситно Кремиковско","кремиковско"],
		["ситно кремиковско","кремиковско"],
		["СИТНО КРЕМИКОВСКО","кремиковско"],
		["Ситното Кремиковско","кремиковско"],
		["ситното кремиковско","кремиковско"],
		["СИТНОТО КРЕМИКОВСКО","кремиковско"],
		["Ситно Кремиковско Хоро","кремиковско"],
		["Ситно Кремиковско хоро","кремиковско"],
		["Ситно кремиковско хоро","кремиковско"],
		["ситно кремиковско хоро","кремиковско"],
		["СИТНО КРЕМИКОВСКО ХОРО","кремиковско"],
		["Ситното Кремиковско Хоро","кремиковско"],
		["Ситното кремиковско хоро","кремиковско"],
		["Ситното Кремиковско хоро","кремиковско"],
		["ситното кремиковско хоро","кремиковско"],
		["СИТНОТО КРЕМИКОВСКО ХОРО","кремиковско"],
	],
    "3per4": [
	  	["Костадине, Мили Сине-Разложките Ергени", "валино"],
		["Костадине, Мили Сине", "валино"],
		["Костадине, мили сине", "валино"],
		["Kostadine, mili sine", "валино"],
		["Kostadine, Mili Sine", "валино"],
	],
    "4per4": [
	  	["Драма", "драма"],
		["ДРАМА", "драма"],
		["драма", "драма"],
		["Drama", "драма"],
		["drama", "драма"],
		["DRAMA", "драма"],
	],
    "5per8": [
	  	["Paydushko horo", "пайдушко"],
		["Paydushko Horo", "пайдушко"],
		["PAZDUSHKO HORO", "пайдушко"],
		["Пайдушко хоро", "пайдушко"],
		["Пайдушко Хоро", "пайдушко"],
		["ПАЙДУШКО ХОРО", "пайдушко"],
		["Край Море Мома Седеше (Пайдушко Хоро)", "пайдушко"],
		["Край море мома седеше (Пайдушко хоро)", "пайдушко"],
		["Krai More Moma Sedeshe (Paidushko Horo)", "пайдушко"],
		["Krai more moma sedeshe (Paidushko horo)", "пайдушко"],
		["Край Море Мома Седеше", "пайдушко"],
		["Край Море Мома Седеше", "пайдушко"],
		["Krai more moma sedeshe", "пайдушко"],
		["Krai More Moma Sedeshe", "пайдушко"],
    ],
    "7per8": [
	  	["Rachenitsa kitka", "ръченица"],
		["Rachenitsa Kitka", "ръченица"],
		["Ruchenitsa Kitka", "ръченица"],
		["Ruchenitsa kitka", "ръченица"],
		["Ръченица китка", "ръченица"],
		["Ръченица Китка", "ръченица"],
		["Melnishkata Moma",""],
		["Melnishkata moma",""],
		["Мелнишката мома",""],
		["Мелнишката Мома",""],
		["Елено Моме", "еленино"],
		["Елено моме", "еленино"],
		["Eleno mome", "еленино"],
		["Eleno Mome", "еленино"],
		["Elenino Horo", "еленино"],
		["Elenino horo", "еленино"],
		["Еленино хоро", "еленино"],
		["Еленино Хоро", "еленино"],
		["ELENINO HORO", "еленино"],
		["ЕЛЕНИНО ХОРО", "еленино"],
		["ЕЛЕНИНОТО ХОРО", "еленино"],
		["Елениното хоро", "еленино"],
		["Елениното Хоро", "еленино"],
		["ЕЛЕНИНОТО", "еленино"],
		["ELENINOTO", "еленино"],
		["ELENINO", "еленино"],
		["Eleninoto", "еленино"],
		["Elenino", "еленино"],
		["Елениното", "еленино"],
		["ЕЛЕНИНО", "еленино"],
		["Eleninoto Horo", "еленино"],
		["Eleninoto horo", "еленино"],
		["ELENINOTO HORO", "еленино"],
		["Zhetvarska Pesen I Rachenitsa", "ръченица"],
		["Zhetvarska pesen i rachenitsa", "ръченица"],
		["Жетварска Песен И Ръченица", "ръченица"],
		["Жетварска песен и ръченица", "ръченица"],
		["Да Кумуваш, Да Кръщаваш (Сватбарска Ръченица)", "ръченица"],
    ],
    "8per8": [
	  	["Lyaski", "пиринско"],
		["lyaski", "пиринско"],
		["Ляски", "пиринско"],
		["ЛЯСКИ", "пиринско"],
		["ляски", "пиринско"],
		["Lqski", "пиринско"],
		["LQSKI", "пиринско"],
		["LYASKI", "пиринско"],
		["lqski", "пиринско"],
		["Pirinsko Horo", "пиринско"],
		["Pirinsko horo", "пиринско"],
		["Пиринско хоро", "пиринско"],
		["Пиринско Хоро", "пиринско"],
		["Пиринското хоро", "пиринско"],
		["Пиринското Хоро", "пиринско"],
		["Пиринското", "пиринско"],
		["ПИРИНСКОТО", "пиринско"],
		["ПИРИНСКО ХОРО", "пиринско"],
		["PIRINSKO HORO", "пиринско"],
		["PIRINSKOTO", "пиринско"],
		["danke", "пиринско"],
		["Danke", "пиринско"],
		["Данке", "пиринско"],
		["данке", "пиринско"],
		["ДАНКЕ", "пиринско"],
		["DANKE", "пиринско"],
    ],
    "9per8": [
	  	["Byala Roza", "самоковско, сватбарско, джангурица"],
		["Byala roza", "самоковско, сватбарско, джангурица"],
		["Bqla Roza", "самоковско, сватбарско, джангурица"],
		["Bqla roza", "самоковско, сватбарско, джангурица"],
		["bqla roza", "самоковско, сватбарско, джангурица"],
		["Бяла роза", "самоковско, сватбарско, джангурица"],
		["Бяла Роза", "самоковско, сватбарско, джангурица"],
		["бяла роза", "самоковско, сватбарско, джангурица"],
		["джангурица", "джангурица"],
		["Джангурица", "джангурица"],
		["ДЖАНГУРИЦА", "джангурица"],
		["Daichovo horo", "дайчово"],
		["Daichovo Horo", "дайчово"],
		["DAYCHOVO HORO", "дайчово"],
		["ДАЙЧОВО ХОРО", "дайчово"],
		["Най-якото Дайчово хоро !!", "дайчово"],
		["Дайчово хоро", "дайчово"],
		["Дайчово Хоро", "дайчово"],
		["Рума мома", "дайчово"],
		["Рума Мома", "дайчово"],
		["Ruma moma", "дайчово"],
		["Ruma Moma", "дайчово"],
		["Слънце Трепти (Дайчово/Варненско Хоро)", "дайчово, варненско"],
		["Oro Se Vie, Mamo","сватбарско, джангурица"],
		["Oro se vie, mamo","сватбарско, джангурица"],
		["Оро се вие, мамо","сватбарско, джангурица"],
		["Оро Се Вие, Мамо","сватбарско, джангурица"],
		["Oro Se Vie Mamo","сватбарско, джангурица"],
		["Oro se vie mamo","сватбарско, джангурица"],
		["Оро се вие мамо","сватбарско, джангурица"],
		["Оро Се Вие Мамо","сватбарско, джангурица"],
		["ОРО СЕ ВИЕ МАМО","сватбарско, джангурица"],
		["ОРО СЕ ВИЕ, МАМО","сватбарско, джангурица"],
		["ORO SE VIE MAMO","сватбарско, джангурица"],
		["ORO SE VIE, MAMO","сватбарско, джангурица"],
		["Grancharskoto", "грънчарско"],
		["grancharskoto", "грънчарско"],
		["gruncharskoto", "грънчарско"],
		["Grancharskoto", "грънчарско"],
		["Грънчарското", "грънчарско"],
		["грънчарското", "грънчарско"],
		["ГРЪНЧАРСКО ХОРО", "грънчарско"],
		["Грънчарско Хоро", "грънчарско"],
		["Грънчарско хоро", "грънчарско"],
		["grancharsko horo", "грънчарско"],
		["Grancharsko Horo", "грънчарско"],
		["gruncharsko horo", "грънчарско"],
		["Grancharsko Horo", "грънчарско"],
		["Moma Yanitsa", "сватбарско"],
		["Moma yanitsa", "сватбарско"],
		["Moma Yanica", "сватбарско"],
		["Moma yanica", "сватбарско"],
		["Moma Qnitsa", "сватбарско"],
		["Moma qnitsa", "сватбарско"],
		["Moma qnica", "сватбарско"],
		["Moma Qnica", "сватбарско"],
		["Мома яница", "сватбарско"],
		["Мома Яница", "сватбарско"],
		["Слънчова Сестра", "криво хоро, джангурица, сватбарско"],
		["Слънчова сестра", "криво хоро, джангурица, сватбарско"],
		["Slunchova sestra", "криво хоро, джангурица, сватбарско"],
		["Slunchova Sestra", "криво хоро, джангурица, сватбарско"],
		["Slanchova sestra", "криво хоро, джангурица, сватбарско"],
		["Slanchova Sestra", "криво хоро, джангурица, сватбарско"],
		["Пиринска Девойка/На Гурбет Отидох – Китка (Джангурица)", "джангурица, сватбарско"],
    ],
	"11per8": [
		["Bistrishka Kopanitsa", "копаница"],
		["Bistrishka kopanitsa", "копаница"],
		["Бистришка копаница", "копаница"],
		["Бистришка Копаница", "копаница"],
		["Полегнала е Тудора", ""],
		["Полегнала е тудора", ""],
		["Полегнала Е Тудора", ""],
		["Полегнала е Тодора", ""],
		["Полегнала е тодора", ""],
		["Полегнала Е Тодора", ""],
		["Polegnala e tudora", ""],
		["Polegnala e Tudora", ""],
		["Polegnala E Tudora", ""],
		["Polegnala e todora", ""],
		["Polegnala e Todora", ""],
		["Polegnala E Todora", ""],
		["POLEGNALA E TODORA", ""],
		["Polegnala e Todora, Полегнала е Тудора", ""],
		["Народна песен / Полегнала е Тодора", ""],
		["Леле Недо", ""],
		["Леле недо", ""],
		["Lele nedo", ""],
		["Lele Nedo", ""],
		["Dafino - vino curveno", ""],
		["Dafino - vino carveno", ""],
		["Dafino - Vino Carveno", ""],
		["Dafino-vino curveno", ""],
		["Dafino-vino carveno", ""],
		["Dafino-Vino Carveno", ""],
		["Дафино-вино цървено", ""],
		["Дафино-Вино Цървено", ""],
		["Дафино-вино цървено", ""],
		["Дафино-Вино Цървено", ""],
		["Дафино Вино", ""],
		["Дафино вино", ""],
		["Dafino vino", ""],
		["Dafino Vino", ""],
		["Дафино Вино / Dafino Vino", ""],
		["Дафино вино / Dafino vino", ""],
		["Дафино Вино/Dafino Vino", ""],
		["Дафино вино/Dafino vino", ""],
		["Дафино, вино цървено", ""],
		["Дафино, Вино Цървено", ""],
		["Dafino, vino curveno", ""],
		["Dafino, Vino Curveno", ""],
		["Dafino, Vino Carveno", ""],
		["Dafino, vino carveno", ""],
		["Dafino Vino Curveno", ""],
		["Dafino Vino Carveno", ""],
		["Dafino vino carveno", ""],
		["Dafino vino curveno", ""],
		["Дафино Вино Цървено", ""],
		["Дафино вино цървено", ""],
		["Дафино, вино црвено", ""],
		["Дафино, Вино Црвено", ""],
		["Dafino vino, curveno", ""],
		["Dafino Vino, Curveno", ""],
		["Dafino Vino, Carveno", ""],
		["Dafino vino, carveno", ""],
		["Дафино вино, цървено", ""],
		["Дафино Вино, Цървено", ""],
		["Dafino, vino crveno", ""],
		["Dafino, Vino Crveno", ""],
		["Dafino vino crveno", ""],
		["Dafino Vino Crveno", ""],
		["Dafino vino, crveno", ""],
		["Dafino Vino, Crveno", ""],
		["Дафино вино, црвено", ""],
		["Дафино Вино, Црвено", ""],
		["Дафино вино црвено", ""],
		["Дафино Вино Црвено", ""],
		["Дафино, вино црвено", ""],
		["DAFINO VINO CRVENO", ""],
		["DAFINO VINO CURVENO", ""],
		["DAFINO VINO CARVENO", ""],
		["ДАФИНО ВИНО ЦЪРВЕНО", ""],
		["ДАФИНО ВИНО ЦРВЕНО", ""],
		["Gankino horo", "ганкино"],
		["Gankino Horo", "ганкино"],
		["GANKINO HORO", "ганкино"],
		["Ганкино хоро", "ганкино"],
		["Ганкино Хоро", "ганкино"],
		["ГАНКИНО ХОРО", "ганкино"],
		["ГАНКИНО", "ганкино"],
		["ганкино", "ганкино"],
		["Gankino", "ганкино"],
		["gankino", "ганкино"],
		["GANKINO", "ганкино"],
		["Стойне Ле Стойне/Магдалено Малка Моме – Китка (Копа�.","ганкино, копаница"],
		["Gankino Horo, Part 1","ганкино"],
		["BELA NEDA",""],
		["Bela neda",""],
		["Bela Neda",""],
		["Бела Неда",""],
		["Бела неда",""],
		["БЕЛА НЕДА",""],
	],
	"12per8": [
	["petrunino", "петрунино"],
	["Petrunino", "петрунино"],
	["PETRUNINO", "петрунино"],
	["petrunino horo", "петрунино"],
	["Petrunino horo", "петрунино"],
	["Petrunino Horo", "петрунино"],
	["PETRUNINO HORO", "петрунино"],
	["petruninoto", "петрунино"],
	["Petruninoto", "петрунино"],
	["PETRUNINOTO", "петрунино"],
	["petruninoto horo", "петрунино"],
	["Petruninoto horo", "петрунино"],
	["Petruninoto Horo", "петрунино"],
	["PETRUNINOTO HORO", "петрунино"],
	["петрунино", "петрунино"],
	["Петрунино", "петрунино"],
	["ПЕТРУНИНО", "петрунино"],
	["петрунино хоро", "петрунино"],
	["Петрунино Хоро", "петрунино"],
	["Петрунино хоро", "петрунино"],
	["ПЕТРУНИНО ХОРО", "петрунино"],
	["петруниното", "петрунино"],
	["Петруниното", "петрунино"],
	["ПЕТРУНИНОТО", "петрунино"],
	["петруниното хоро", "петрунино"],
	["Петруниното Хоро", "петрунино"],
	["Петруниното хоро", "петрунино"],
	["ПЕТРУНИНОТО ХОРО", "петрунино"],
	["Petrunino horo /Петрунино хоро", "петрунино"],
	["Petrunino Horo /Петрунино Хоро", "петрунино"],
	["Petrunino horo / Петрунино хоро", "петрунино"],
	["Petrunino Horo / Петрунино Хоро", "петрунино"],
	["Petrunino horo/Петрунино хоро", "петрунино"],
	["Petrunino Horo/Петрунино Хоро", "петрунино"],
  ]
}

export const danceTypes = {
	"2per4":"2/4",
 	"3per4":"3/4",
 	"4per4":"4/4",
 	"5per8":"5/8, 5/16",
 	"7per8":"7/8",
 	"8per8":"8/8",
 	"9per8":"9/8, 9/16",
	"11per8":"11/8, 11/16",
	"12per8":"12/8, 13/8",
}