import { React, useState, useEffect } from 'react';
import img from '../img/logo_button.png';
import Fade from "react-reveal";
import styled from 'styled-components';
import ShazamRecorder from '../Recorder';
import { Shazam } from '../Shazam';
import { dances, danceTypes } from '../Dances';

const RecordMusicImgBtn = styled.img`
border-radius: 10%;
border: 2px solid black;
 
  ${props => props.cursor && "cursor: pointer;"}
  animation: ${props => props.pulse ? `pulse 3000ms infinite`:``};

  @keyframes pulse {
    0% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
    }
    50% {
      transform: scale(0.9);
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.4);
    }
    100% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
    }
  }
`;

const Header = (props) =>  {

const [pulse, setPulse] = useState(false);
const [cursor, setCursor] = useState(true);
const [shazamResponse, setShazamResponse] = useState('');
const [tooltipText, setTooltipText] = useState('');
const [trackTitleText, setTrackTitleText] = useState('');
const [danceTypeText, setDanceTypeText] = useState('');
const [danceText, setDanceText] = useState('');

// Функцията, която се изпълнява когато се получи отговор от Shazam API
useEffect(() => {
  if(shazamResponse){
    // Проверява се дали има засечена песен
    if (shazamResponse["matches"].length > 0) {
      let dance = '';
      let danceType = '';

      // Минаваме през всички тактове в базата данни и търсим дали можем да намерим заглавието на песента между тях
      Object.keys(dances).forEach((_danceType) => {
        const found = dances[_danceType]
          .filter((songDance) => songDance[0] === shazamResponse.track.title);
        if(found.length > 0){
          dance = found[0][1];
          danceType = _danceType;
        }
      });

      // Проверяваме дали песента е в нашата база данни и показваме данните за нея, а в противен случай се изписва, че не е народна песен
      if(danceType){
        if(!dance){ 
          dances[danceType].forEach((songDance) => {
            dance += `${songDance[1]} `;
          })
        }

        clearTooltip();
        const danceTypeStr = danceTypes[danceType];
        setTrackTitleText(`Песен: ${shazamResponse.track.title} - ${shazamResponse.track.subtitle}`);
        setDanceTypeText(`Музикален размер: ${danceTypeStr}`);
        setDanceText(`Подходящи народни хора̀: ${dance}`);
        setTooltipText(' ');
      } else {
        clearTooltip();
        setTooltipText(`Песента ${shazamResponse.track.title} - ${shazamResponse.track.subtitle} не е народна песен`);
      }
    // Ако не се засече никаква песен изписваме на екрана, че не е намерена песен
    } else {
      clearTooltip();
      setTooltipText('Песента не е намерена');
    }
  }
}, [shazamResponse]);

  // Функция за изчистване на полето под главния бутон
  const clearTooltip = () => {
    setTooltipText('');
    setTrackTitleText('');
    setDanceTypeText('');
    setDanceText('');
  }

  if (!props.data) return null;

  const name = props.data.name;
  const description = props.data.description;
  const tooltip = props.data.tooltip;

// Функция, която се изпълнява като се натисне бутона с логото на приложението
const recordMusicImgBtnClick = () => {
  // Бутона започва да пулсира
  setCursor(false);
  setPulse(true);

  // Инициализираме записвачката от Recorder.js
  const recorder = ShazamRecorder();

  // Започваме записване
  recorder.start();

  // Изчакване за да се запише четири секунден аудио клип
  setTimeout(() => {
    // Записването се спира
    recorder.stop();

    // Преобразуваме данните от PCM blob към Base64 string и изпращаме http заявка чрез Shazam.search функцията идваща от Shazam.js
    recorder.pcm16ToB64((base64Data) => {
      const shazam = Shazam();
      shazam.search(base64Data, (response) => {
        setShazamResponse(response);
      });
    }, true)

    // Бутона продължава да пулсира за още две секунди за да компенсира времето за получаване на отговор от Shazam API
    setTimeout(() => {
      setCursor(true);
      setPulse(false);
    }, 2000)
  }, 4000);
}

  return (
    <header id="home" style={{ backgroundImage: `url(images/background.png)`, width:`100%` }}>
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Покажи навигацията
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Скрий навигацията
        </a>

        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              Начало
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#resume">
              Фолклорни области
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              За нас
            </a>
          </li>
          {/* <li>
            <a className="smoothscroll" href="#contact">
              Връзка с нас
            </a>
          </li> */}
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <h1 className="responsive-headline">{name}</h1>
          </Fade>
          <Fade bottom duration={1200}>
            <h3>{description}</h3>
          </Fade>
          <hr />
          <div className="tooltip">
          <RecordMusicImgBtn pulse={pulse} cursor={cursor} onClick={recordMusicImgBtnClick}  src={img}/>
          <span class="tooltiptext">
            { tooltipText ? tooltipText : tooltip }
            { trackTitleText ? <p>{trackTitleText}</p> : '' }
            { danceTypeText ? <p>{danceTypeText}</p> : '' }
            { danceText ? <p>{danceText}</p> : '' }
          </span>
          </div> 
        </div>
      </div>
    </header>
  );
}

export default Header;