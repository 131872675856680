import './App.css';
import Recorder from 'js-audio-recorder';

const ShazamRecorder = () => {
  // Инициализираме js-audio-recorder като му задаваме опциите за записване 
  let recorder = new Recorder({
    sampleBits: 16,
    sampleRate: 44100,
    numChannels: 1,
	});
	let PCMData;

  // Функция за започване на записа
  const start = () => { return recorder.start()}

  // Функция за спиране на записа и връщане на данните в PCM формат
	const stop = () => {
		PCMData = recorder.getPCMBlob()
    return PCMData;
	}
  
  // Функция за преобразуване на PCM данните към Base 64 string
	const pcm16ToB64 = (result, removeHeader, data) => {
		const reader = new FileReader();
        const dataToProcess = data ? data : PCMData;
        reader.readAsDataURL(dataToProcess);
        reader.onloadend = function () {
            const base64String = removeHeader ? reader.result.split('base64,')[1]: reader.result;
            result(base64String);
        }
	}

  return {start, stop, pcm16ToB64};
}

export default ShazamRecorder;