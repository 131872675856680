export const Shazam = () => {

  const search = (data, result) => {
  	// Инициализираме Axios, което е библиотека за създаване на http заявки
		const axios = require('axios');

		// Опциите и данните на заявката
		const options = {
			method: 'POST',
			url: 'https://shazam.p.rapidapi.com/songs/detect',
			headers: {
				'content-type': 'text/plain',
				'x-rapidapi-host': 'shazam.p.rapidapi.com',
				'x-rapidapi-key': 'e1499ac137mshfe4874e776c54f9p16f92djsnc8be7ebad4da'
			},
			data,
		};

		// Изпращане на заявката към Shazam API
		axios.request(options).then(function (response) {
			result(response.data);
		}).catch(function (error) {
			console.error(error);
		});
	}
  
	return {search};
}

